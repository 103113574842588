<template lang="pug">
.content
  .content-firm(v-if="subTabSelect === 1")
    .options
      .label(style="width: 150px;") 充值金额:
      el-input(v-model="depositPrice" placeholder="请输入充值金额" style="width: 300px;")
    .pay-code
      img(src="/img/userinfo-icon/empty.png", alt="", srcset="")
    .btns
      .deposit 充值
  .content-firm(v-if="subTabSelect === 2")
    .options
      .label(style="width: 150px;") 充值金额:
      el-input(v-model="depositPrice" placeholder="请输入充值金额" style="width: 300px;")
    .btns
      .deposit 前往充值
  .content-firm(v-if="subTabSelect === 3")
    .duigong
      .taps
        .tab-name 1
        .tab-name-line
        .tab-name 2
      .taps-content
        span(style="margin-bottom: 30px;display: inline-block;") · 使用您公司的银行账户，转账至全倾的银行收款账户
        .options(style="margin-bottom: 10px;")
          .label-text(style="width: 80px;") 收款户名:
          .params-text 北京全倾科技有限公司
        .options(style="margin-bottom: 10px;")
          .label-text(style="width: 80px;") 收款银行:
          .params-text 招商银行北京朝阳公园支行（联行号：308100005473）
        .options(style="margin-bottom: 30px;")
          .label-text(style="width: 80px;") 明细单号:
          .params-text 收款账号    1109 4195 1610 902
        span(style="margin-bottom: 30px;display: inline-block;") · 等待1-5个工作日，客服核验汇款后将为您帐号充值
        br
        span(style="color: red;display: inline-block;") · 请务必在汇款时，在附言处填写您要充值的帐号，即您当前的注册帐号，可查看全倾网站的右上角即是。如未注明，则汇款将会被原路退回。
  .content-firm(v-if="subTabSelect === 4")
    .options
      .label(style="width: 180px;") 充值至用户 ID:
      el-input(v-model="depositPrice" placeholder="请输入充值至用户 ID" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值金额:
      el-input(v-model="depositPrice" placeholder="请输入充值金额" style="width: 300px;") 元
    .options
      .label(style="width: 180px;") 充值至账户:
      el-input(v-model="depositPrice" placeholder="请输入充值至账户" style="width: 300px;")
    .options
      .label(style="width: 180px;") 公司信用代码:
      el-input(v-model="depositPrice" placeholder="请输入公司信用代码" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值方式:
      el-select(v-model="account" placeholder="请选择充值方式" style="width: 300px;")
        el-option(
          v-for="item in accountList"
          :key="item.value"
          :label="item.name"
          :value="item.value")
    .options
      .label(style="width: 180px;") 充值来源账户:
      el-input(v-model="depositPrice" placeholder="请输入充值来源账户" style="width: 300px;")
    .options
      .label(style="width: 180px;") 来源账户名称:
      el-input(v-model="depositPrice" placeholder="请输入来源账户名称" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值来源银行:
      el-input(v-model="depositPrice" placeholder="请输入充值来源银行" style="width: 300px;")
    .btns(style="padding-left: 30px;")
      .deposit 新增
</template>
<script>
export default {
  data() {
    return {
      accountList: [],
      timer: null,
      count: 0,
      show: true,
      price: '68.00',
      depositPrice: '',
      account: '',
      phoneCode: ''
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    subTabSelect: Number
  },
  mounted() {},
  methods: {
    getCode() {
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .duigong {
    display: flex;
    justify-content: flex-start;
    .taps {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    .taps-content {
      flex: 1;
    }
    .tab-name {
      background-color: #5ad3cd;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      align-items: center;
    }
    .tab-name-line {
      width: 0px;
      height: 143px;
      border-left: 1px solid #acacac;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .label {
    width: 180px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 18px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #5ad3cd;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #fff;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin-left: 150px;
  }
  .pay-code {
    width: 178px;
    height: 178px;
    margin-bottom: 30px;
    margin-left: 150px;
    img {
      width: 178px;
      height: 178px;
    }
  }
}
</style>
