<template lang="pug">
.content
  .content-firm
    .options
      .label 现有金额:
      .params {{ myAccountIncomeInfo.balance }}元
    .options
      .label 提现金额:
      el-input(v-model="amount" placeholder="请输入提现金额" style="width: 300px;") 
      span(style="font-size: 22px;margin-left: 8px; line-height: 40px;") 元
    .options(v-if="amount && priceAccount && selectAccount !== 'person'")
      .label  劳务所得税:
      .params {{ priceAccount.individualIncomeTax }}元
    .options(v-if="amount && priceAccount")
      .label  所得金额:
      .params {{ priceAccount.incomeAmount }}元
    .options
      .label  提现至:
      .params 
        el-button(:type="selectAccount === 'person' ? 'primary' : ''" round @click="selectAccount='person'") 个人账户
        el-button(:type="selectAccount !== 'person' ? 'primary' : ''" round @click="selectAccount='company'") 公司账户
    .options(v-if="selectAccount === 'person'")
      .label 个人支付宝账号:
      el-input(v-model="alipayAccount" placeholder="请输入支付宝账号" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡账号:
      el-input(v-model="companyAccountNumber" placeholder="请输入公司卡账号" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡名称:
      el-input(v-model="companyAccountName" placeholder="请输入公司卡名称" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡开户银行:
      el-input(v-model="companyAccountBankSubbranch" placeholder="请输入公司卡开户银行" style="width: 300px;")
    .options
      .label  短信验证:
      .params
        el-input(placeholder="短信验证码" v-model="phoneCode" class="change-password-input")
        .code-desc （发送至注册手机号）
        button(@click.prevent="getCode()" class="code-btn" :disabled="!show")
          span(v-show="show") 获取验证码
          span(v-show="!show" class="count") {{ count }}
    .btns
      .deposit 提现
</template>
<script>
import {getMyAccountIncomeApi, withdrawInfo, incomeWithdraw} from '../../api/account'
import {getVerifyCodeApi} from '@/api/index'
import {currentLoginUser} from '@/api/project'

export default {
  data() {
    return {
      timer: null,
      count: 0,
      show: true,
      price: '',
      alipayAccount: '',
      amount: '',
      myAccountIncomeInfo: {},
      priceAccount: {},
      phoneCode: '',
      companyAccountName: '',
      companyAccountNumber: '',
      companyAccountBankSubbranch: '',
      selectAccount: 'person'
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    this.init()
    currentLoginUser().then(res => {
      console.log('%c [ res ]-79', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      this.phoneNumber = res.data.phoneNumber
    })
  },
  watch: {
    depositPrice() {
      withdrawInfo({
        withdrawAmount: this.amount,
        identityType: this.selectAccount === 'person' ? '个人账户' : '企业账户'
      }).then(res => {
        this.priceAccount = res.data
        console.log(
          '%c [ this.priceAccount  ]-86',
          'font-size:13px; background:pink; color:#bf2c9f;',
          this.priceAccount
        )
      })
    }
  },
  methods: {
    init() {
      getMyAccountIncomeApi().then(res => {
        this.myAccountIncomeInfo = res.data
      })
    },
    incomeWithdraw() {
      const params = {
        amount: this.amount,
        identityType: this.selectAccount === 'person' ? '个人账户' : '企业账户',
        verifyCode: this.phoneCode
      }
      if (this.selectAccount === 'person') {
        params.alipayAccount = this.alipayAccount
      } else {
        params.companyAccountName = this.companyAccountName
        params.companyAccountNumber = this.companyAccountNumber
        params.companyAccountBankSubbranch = this.companyAccountBankSubbranch
      }
      incomeWithdraw(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.amount = ''
        this.priceAccount = {}
        this.init()
        this.$message.success('提现成功')
      })
    },
    getCode() {
      console.log('%c [ this.phoneNumber ]-129', 'font-size:13px; background:pink; color:#bf2c9f;', this.phoneNumber)
      if (this.phoneNumber === '') {
        this.$message.warning('请输入手机号')
        return
      }
      if (!/^1[3-9]\d{9}$/.test(this.phoneNumber)) {
        this.$message.warning('请输入正确手机号')
        return
      }
      //axios请求
      // 验证码倒计时
      getVerifyCodeApi({phone: this.phoneNumber, biz: 6})
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pr {
  position: relative;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -100px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  padding-left: 10px;
  background-color: #fff;
  line-height: 36px;
  cursor: pointer;
}
.change-password-input {
  width: 300px;
}
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
}
</style>
