<template lang="pug">
.content
  .content-firm
    .options
      .label 现有金额:
      .params {{ price }}元
    .options
      .label 提现金额:
      el-input(v-model="depositPrice" placeholder="请输入提现金额" style="width: 300px;")
    .options
      .label  提现至支付宝账户:
      el-input(v-model="account" placeholder="请输入账户" style="width: 300px;")
    .options
      .label  短信验证:
      .params
        el-input(placeholder="短信验证码" v-model="phoneCode" class="change-password-input")
        .code-desc （发送至注册手机号）
        button(@click.prevent="getCode()" class="code-btn" :disabled="!show")
          span(v-show="show") 获取验证码
          span(v-show="!show" class="count") {{ count }}
    .btns
      .deposit 提现
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      count: 0,
      show: true,
      price: '68.00',
      depositPrice: '',
      account: '',
      phoneCode: ''
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {},
  methods: {
    getCode() {
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pr {
  position: relative;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -100px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  padding-left: 10px;
  background-color: #fff;
  line-height: 36px;
  cursor: pointer;
}
.change-password-input {
  width: 300px;
}
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 18px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
}
</style>
