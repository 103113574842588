import request from '@/utils/request'

// 企业账户
export const getCompanyAccountInfoApi = () =>
  request({
    url: '/userAccount/queryCompanyUserAccountInfos',
    method: 'get'
  })
// 个人账户
export const getPersonAccountInfoApi = () =>
  request({
    url: '/userAccount/getPersonalAccountInfo',
    method: 'get'
  })
// 充值记录下拉选
export const getAccountOptionsApi = params =>
  request({
    url: '/userAccount/rechargeList/options',
    method: 'get',
    params
  })
// 个人充值记录
export const getRechargeListApi = params =>
  request({
    url: '/userAccount/recharge/list',
    method: 'get',
    params
  })
// 审批通过
export const passApi = params =>
  request({
    url: '/userAccount/recharge/pass',
    method: 'post',
    data: JSON.stringify(params)
  })
// 审批拒绝
export const refuseApi = params =>
  request({
    url: '/userAccount/recharge/reject',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人提现记录
export const getDepositListApi = params =>
  request({
    url: '/userAccount/withdraw/list',
    method: 'get',
    params
  })
// 提现-置成功
export const postPersonalAccountSuccessApi = params =>
  request({
    url: '/userAccount/withdraw/success',
    method: 'post',
    data: JSON.stringify(params)
  })
// 提现-置失败
export const postPersonalAccountFailedApi = params =>
  request({
    url: '/userAccount/withdraw/failed',
    method: 'post',
    data: JSON.stringify(params)
  })
// 提现-置取消
export const postPersonalAccountCancelApi = params =>
  request({
    url: '/userAccount/withdraw/cancel',
    method: 'post',
    data: JSON.stringify(params)
  })
// 收支明细
export const getIncomeRecordApi = params =>
  request({
    url: '/userAccount/queryIncomeAndExpensesRecordList',
    method: 'get',
    params
  })
// 个人中心-提现记录-删除文件
export const postDeleteFileApi = params =>
  request({
    url: '/userAccount/withdraw/removeFile',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-我的收入
export const withdrawInfo = params =>
  request({
    url: '/userAccount/income/withdraw/info',
    method: 'get',
    params
  })

export const incomeWithdraw = params =>
  request({
    url: '/userAccount/income/withdraw',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-我的收入
export const getMyAccountIncomeApi = () =>
  request({
    url: '/userAccount/income',
    method: 'get'
  })
// 个人中心-收入-收入记录
export const getIncomeRecordListApi = params =>
  request({
    url: '/userAccount/income/records',
    method: 'get',
    params
  })
// 个人中心-收入-提现记录
export const getWithdrawRecordListApi = params =>
  request({
    url: '/userAccount/income/withdraw/records',
    method: 'get',
    params
  })
// 个人中心-收入-提现记录-删除文件
export const postDeleteWithdrawFileApi = params =>
  request({
    url: '/userAccount/income/withdraw/removeFile',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-提现记录-置成功
export const postUserIncomeWithdrawSuccessApi = params =>
  request({
    url: '/userAccount/income/withdraw/success',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-提现记录-置失败
export const postUserIncomeWithdrawFailedApi = params =>
  request({
    url: '/userAccount/income/withdraw/failed',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-提现记录-置取消
export const postUserIncomeWithdrawCancelApi = params =>
  request({
    url: '/userAccount/income/withdraw/cancel',
    method: 'post',
    data: JSON.stringify(params)
  })
// 个人中心-收入-提现记录-取消提现
export const postUserIncomeWithdrawUserCancelApi = params =>
  request({
    url: '/userAccount/income/withdraw/userCancel',
    method: 'post',
    data: JSON.stringify(params)
  })
