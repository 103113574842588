<template lang="pug">
.content
  .search-tab
    .search-account
      el-select(v-model="account" placeholder="请选择账户" style="margin-right: 20px;")
        el-option(
          v-for="item in accountOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id")
      el-select(v-model="status" placeholder="请选择状态")
        el-option(
          v-for="item in statusList"
          :key="item.value"
          :label="item.name"
          :value="item.value")
    .search-code
      el-select(v-model="searchTypeName" placeholder="请选择搜索类型" style="margin-right: 20px;width: 250px;")
        el-option(
          v-for="item in searchType"
          :key="item.value"
          :label="item.name"
          :value="item.value")
      el-input(v-model="searchInputValue" placeholder="请输入内容")
  el-divider
  .content-firm(v-for="(item, index) in info" v-if="info.length > 0")
    .options
      .label 提现单号:
      .params {{ item.withdrawId }}
    .options
      .label 提现金额:
      .params {{ item.withdrawAmount }}元
    .options
      .label 提现账户:
      .params {{ item.accountName }}
    .options
      .label 提现前余额:
      .params {{ item.accountBalanceBeforeWithdraw }}
    .options
      .label 提现后余额:
      .params {{ item.accountBalanceAfterWithdraw }}
    .options
      .label 提现方式:
      .params {{ item.channel }}
    .options
      .label 提现至账户:
      .params {{ item.toAccountNumber }}
    .options(v-if="item.channel !== '支付宝'")
      .label 提现至名称:
      .params {{ item.toAccountName }}
    .options(v-if="item.channel !== '支付宝'")
      .label 提现至银行:
      .params {{ item.toAccountBankSubbranch }}
    .options
      .label 提现时间:
      .params {{ item.createTime }}
    .options(v-if="isOperator")
      .label 用户ID:
      .params {{ item.userPhoneNumber }}
    .options(style="margin-bottom: 28px;")
      .label 状态:
      el-tag(:type="statusTagMap[item.status]") {{statusTextMap[item.status]}}
    .options(v-if="item.refuseReason.length > 0 && !item.financeManageOperatorFlag || item.financeManageOperatorFlag" style="margin-bottom: 28px;")
      .label 原因:
      el-input(v-model="item.refuseReason" :disabled="item.status !== 1" placeholder="请输入内容" style="width: 500px")
    .options(v-if="item.channel !== '支付宝'" style="margin-bottom: 28px;")
      .label 转账截图:
      el-upload(class="avatar-uploader"
        :file-list="item.picUrls"
        action="https://www.quanqingkeji.com/api/userAccount/withdraw/uploadFile"
        list-type="picture-card"
        :limit="1"
        :headers="{ token }"
        :data="{recordId: item.recordId}"
        :disabled="!item.financeManageOperatorFlag"
        :before-upload="(file) => beforeAvatarUpload(file, index)"
        :on-success="(res, file) => {handleAvatarSuccess(res, file, index)}"
        :on-remove="(res, file) => handleRemove(res, file, index)")
        i(:class="['el-icon-plus', 'avatar-uploader-icon', 'disabled-mouse' && !item.financeManageOperatorFlag]")
    .options(v-if="item.financeManageOperatorFlag")
      .label 处理人ID:
      .params {{ item.auditUserPhoneNumber }}
    .btns(v-if="item.channel !== '支付宝' && item.financeManageOperatorFlag")
      .recharge(@click="pass(item)") 置成功
      .deposit(@click="refuse(item)") 置失败
      .deposit(@click="cancel(item)") 置取消
    el-divider(v-if="info.length && index < info.length - 1")
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
  .empty(v-if="info.length === 0")
    img.coupon-empty(src="/img/userinfo-icon/empty.png" class="")
    span.coupon-empty-text 暂无数据
</template>
<script>
import { getAccountOptionsApi, getDepositListApi, postPersonalAccountSuccessApi, postPersonalAccountFailedApi, postPersonalAccountCancelApi, postDeleteFileApi } from '../../api/account';
import { checkAuth } from '@/api/product';

const searchTypeTemplate = [
  {
    value: 2,
    name: '用户ID'
  },
  {
    value: 3,
    name: '提现账户'
  },
  {
    value: 4,
    name: '信用代码'
  },
  {
    value: 5,
    name: '提现至账户'
  },
  {
    value: 6,
    name: '提现至名称'
  },
  {
    value: 7,
    name: '处理人ID'
  }
]
export default {
  data() {
    return {
      isOperator:false,
      info: [],
      total: 0,
      page: 1,
      pageSize: 10,
      accountOptions: [],
      searchType: [
        {
          value: 1,
          name: '提现单号'
        }
      ],
      statusList: [
        {
          value: -1,
          name: '全部状态'
        },
        {
          value: 1,
          name: '待处理'
        },
        {
          value: 2,
          name: '提现成功'
        },
        {
          value: 3,
          name: '提现失败'
        },
        {
          value: 4,
          name: '已取消'
        }
      ],
      statusTagMap: {
        1: '',
        2: 'success',
        3: 'danger',
        4: 'info',
      },
      statusTextMap: {
        1: '待处理',
        2: '提现成功',
        3: '提现失败',
        4: '已取消',
      },
      status: '',
      account: '',
      searchTypeName: '',
      searchInputValue: '',
    }
  },
  watch: {
    status() {
      this.page = 1
      this.search()
    },
    account() {
      this.page = 1
      this.search()
    },
    searchInputValue() {
      this.page = 1
      this.search()
    }
  },
  created() {
    this.token = localStorage.getItem('TOKEN');
    // 是否是运营
    checkAuth({ and: true, authList: [1] }).then(res => {
      this.isOperator = res.code === 200; 
      this.searchType = res.code === 200 ? [...this.searchType, ...searchTypeTemplate] : this.searchType
    })
  },
  mounted() {
    this.getAccountOptions().then(() => {
      this.search()
    })
  },
  methods: {
    getAccountOptions() {
      return getAccountOptionsApi().then(res => {
        this.accountOptions = res.data;
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res, file, index) {
      this.info[index].picUrls = [file.url];
    },
    handleRemove(res, file, index) {
      const params = {
        recordId: this.info[index].recordId
      }
      postDeleteFileApi(params).then(() => {
        this.$message.success('删除成功')
      })
    },
    // 置成功
    pass(item) {
      const params = {
        recordId: item.recordId
      }
      postPersonalAccountSuccessApi(params).then(() => {
        this.$message.success('提现记录置成功！')
        this.search()
      })
    },
    // 置失败
    refuse(item) {
      if(item.refuseReason.length <= 0) {
        this.$message.error('请填写失败原因！')
        return;
      }
      const params = {
        recordId: item.recordId,
        refuseReason: item.refuseReason,
      }
      postPersonalAccountFailedApi(params).then(() => {
        this.$message.success('提现记录置失败！')
        this.search()
      })
    },
    // 置取消
    cancel(item) {
      if(item.refuseReason.length <= 0) {
        this.$message.error('请填写取消原因！')
        return;
      }
      const params = {
        recordId: item.recordId,
        refuseReason: item.refuseReason,
      }
      postPersonalAccountCancelApi(params).then(() => {
        this.$message.success('提现记录置取消！')
        this.search()
      })
    },
    search() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.page,
        accountId: this.account,
        queryType: this.searchTypeName,
        status: this.status,
        queryContent: this.searchInputValue
      }
      getDepositListApi(params).then(res => {
        const {records, total} = res.data;
          const newInfo = records.map(record => {
            const newRecord = {...record}
            if(newRecord.picUrls.length) {
              newRecord.picUrls = [{url: record.picUrls[0]}]
            }
            return newRecord;
          })
          this.info = newInfo;
          this.total = total;
        })
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 55px 88px;
  flex-direction: column;
  position: relative;
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .search-account,
  .search-code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .search-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 160px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;
  }
  .options {
    margin-bottom: 32px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 158px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
